
import {
    menuToTree
} from "@/utils/common.js"

function treeMap(tree, cb) {
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        cb && cb(node);

        if (node.children) {
            treeMap(node.children)
        }
    }
}

export default {
    name: "RoleAuth",
    props: {
        data: Object
    },
    data() {
        return {
            // 角色模块数据
            role: {
                checkedKeys: [],
                selectedKeys: []
            },
            // 菜单模块数据
            menu: {
                checkedKeys: [],
                selectedKeys: [],
                expandedKeys: []
            },
            // 模块激活
            activeKey: "user",

            searchValue: "",

            expandedKeys: [],
            selectedKeys: [],

            // 菜单列表
            menus: [],
            // 角色列表
            roles: [],
            // 提交状态
            loading: false
        };
    },
    watch: {
        data() {
            this.getRoleAuth();
        }
    },
    methods: {
        /**
         * 获取权限列表
         */
        getRoleAuth() {
            if (!this.data || !this.data.id) {
                return
            }
            this.$http.get(`/admin/role/list_role_privilege/${this.data.id}`).then(res => {
                if (res.data.code === 0) {
                    const result = res.data.data.filter(i => i.checked).map(i => i.menu_id);
                    treeMap(this.menus, (node) => {
                        const index = result.findIndex(i => i == node.id);
                        index != -1 && result.splice(index,1);
                    })
                    this.menu.checkedKeys = result;
                }
            })
        },
        /**
         * 获取菜单
         */
        getMenu() {
            this.$http.get("/admin/menu/admin/list").then(res => {
                if (res.data.code === 0) {
                    this.menus = menuToTree(res.data.data)
                }
            })
        },
        /**
         * 保存
         */
        save() {
            this.loading = true;
            const treeData = Object.keys(this.$refs.treeRef.tree.domTreeNodes).map(k => this.$refs.treeRef.tree.domTreeNodes[k]);
            const arr = [];
            // 递归获取全部菜单-抓取harf
            const treeRender = (data) => {
                function map(tree) {
                    for (let i = 0; i < tree.length; i++) {
                        const node = tree[i]
                        if (node.halfChecked) {
                            arr.push(node.eventKey)
                        }

                        if (node.children) {
                            map(node.children);
                        }
                    }
                }

                map(data)
            }

            treeRender(treeData);

            this.$http.post(`/admin/role/update_role_privilege/${this.data.id}`, {
                ids: [...this.menu.checkedKeys, ...arr]
            }).then(res => {
                if (res.data.code === 0) {
                    this.$emit("done");
                    this.$message.success(res.data.msg)
                } else {
                    this.$message.error(res.data.msg)
                }
            }).finally(() => {
                this.loading = false;
            })
        }
    },
    async mounted() {
        await this.getMenu();
        this.getRoleAuth();
    }
}
